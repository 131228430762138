@keyframes moveInCircle {
    0% {
      transform: rotate(0deg) translateX(10px) rotate(0deg);
    }
    25% {
      transform: rotate(90deg) translateX(10px) rotate(-90deg);
    }
    50% {
      transform: rotate(180deg) translateX(10px) rotate(-180deg);
    }
    75% {
      transform: rotate(270deg) translateX(10px) rotate(-270deg);
    }
    100% {
      transform: rotate(360deg) translateX(10px) rotate(-360deg);
    }
  }
  
  .icon-animation {
    animation: moveInCircle 4s linear infinite;
  }
  